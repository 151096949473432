@import 'css/config.scss';
@import 'css/mixins.scss';

.container-entrada {
    margin: 0 auto;
    max-width: $max-width;
    width: 90%;

    @include breakpoint($grid-breakpoint-m) {
        width: 85%;
    }

    @include breakpoint($grid-breakpoint-l) {
        width: 70%;
    }

    .row-entrada {
        margin-left: (-1 * $gutter-width / 2);
        margin-right: (-1 * $gutter-width / 2);
    }
}

.section {
    padding-bottom: 1rem;
    padding-top: 1rem;

    &.no-pad {
        padding: 0;
    }

    &.no-pad-bot {
        padding-bottom: 0;
    }

    &.no-pad-top {
        padding-top: 0;
    }
}


.row-entrada {
    margin: 0 auto $spacing-m;

    &:after {
        clear: both;
        content: '';
        display: table;
    }

    &.no-pad {
        margin-left: -$gutter-width / 2;
        margin-right: -$gutter-width / 2;
    }

    .col {
        box-sizing: border-box;
        float: left;
        min-height: 1px;
        padding: 0 $gutter-width / 2;

        &[class*='push-'],
        &[class*='pull-'] {
            position: relative;
        }

        & + .row-description {
            float: left;
            margin-top: -$spacing-m;

            @include breakpoint($tablet) {
                margin-top: 0;
            }
        }

        $i: 1;
        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + '%');

            &.s#{$i} {
                left: auto;
                margin-left: auto;
                right: auto;
                width: $perc;
            }

            $i: $i + 1;
        }

        $i: 1;

        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + '%');

            &.offset-s#{$i} {
                margin-left: $perc;
            }

            &.pull-s#{$i} {
                right: $perc;
            }

            &.push-s#{$i} {
                left: $perc;
            }

            $i: $i + 1;
        }

        // Used for $phone-large sizes
        // '.pl' means 'phone large'
        @include breakpoint($grid-breakpoint-pl) {

            $i: 1;

            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + '%');

                &.pl#{$i} {
                    left: auto;
                    margin-left: auto;
                    right: auto;
                    width: $perc;
                }

                $i: $i + 1;
            }

            $i: 1;

            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + '%');

                &.offset-pl#{$i} {
                    margin-left: $perc;
                }

                &.pull-pl#{$i} {
                    right: $perc;
                }

                &.push-pl#{$i} {
                    left: $perc;
                }

                $i: $i + 1;
            }
        }

        @include breakpoint($grid-breakpoint-m) {

            $i: 1;

            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + '%');

                &.m#{$i} {
                    left: auto;
                    margin-left: auto;
                    right: auto;
                    width: $perc;
                }

                $i: $i + 1;
            }

            $i: 1;

            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + '%');

                &.offset-m#{$i} {
                    margin-left: $perc;
                }

                &.pull-m#{$i} {
                    right: $perc;
                }

                &.push-m#{$i} {
                    left: $perc;
                }

                $i: $i + 1;
            }
        }

        @include breakpoint($grid-breakpoint-l) {

            $i: 1;

            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + '%');

                &.l#{$i} {
                    left: auto;
                    margin-left: auto;
                    right: auto;
                    width: $perc;
                }

                $i: $i + 1;
            }

            $i: 1;

            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + '%');

                &.offset-l#{$i} {
                    margin-left: $perc;
                }

                &.pull-l#{$i} {
                    right: $perc;
                }

                &.push-l#{$i} {
                    left: $perc;
                }

                $i: $i + 1;
            }
        }
    }

    .row-description {
        @include input-description;
        box-sizing: border-box;
        // This padding overwrites the one from input-description
        padding: ($input-font-size / 2) ($input-font-size + $gutter-width / 2) 0 ($input-font-size + $gutter-width / 2);
        width: 100%;

        &:empty {
            display: none;
        }
    }
}

/*
 * Heavily inspired on Bootstrap Flex utilities:
 * https://getbootstrap.com/docs/4.0/utilities/flex/
 * Note that we need the !important here (even Bootstrap is doing it)
 * We need the !important when we want to combine (for i.e.) flex-column and flex-m-row,
 * So by default is column but for >=tablet we want row.
*/
.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-m-row {
    @include breakpoint($tablet) {
        display: flex;
        flex-direction: row !important;
    }
}

.flex-l-row {
    @include breakpoint($desktop-small) {
        display: flex;
        flex-direction: row !important;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-m-column {
    @include breakpoint($tablet) {
        display: flex;
        flex-direction: column !important;
    }
}

.flex-l-column {
    @include breakpoint($desktop-small) {
        display: flex;
        flex-direction: column !important;
    }
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-center {
    align-items: center;
}

@for $i from 1 to 6 {
    .flex-grow-#{$i} {
        flex-grow: $i;
    }
}
