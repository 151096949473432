.no-scroll {
    overflow: hidden;
}

.main-nav.opened.push {
    transform: translateX(-260px);
}

.main-nav.opened.push-again {
    transform: translateX(-290px);
}

// Trigger for hover on desktop dropdown menu
@media (min-width: $main-nav-breakpoint) {
    .nav-item-help-link {
        &:hover,
        &:active {
            .sub-menu-wrap {
                display: block;
            }
        }
    }
}

.help-link {

    .forward-arrow {
        font-size: $font-size-xlarge;
        margin-left: -3px;

        @include breakpoint($main-nav-breakpoint) {
            font-size: inherit;
            transform: rotate(90deg);
        }
    }
}

.sub-menu-wrap {
    display: none;
    left: 0;
    position: absolute;
    top: 0;

    &.opened {
        display: block;
    }


    @include breakpoint(0, ($main-nav-breakpoint - 1) ) {
        display: block;
        background: $bg-color-secondary;
        left: 100%;
        overflow: hidden;
        padding-top: $main-header-height;
        transform: translateX(0);
        transition: $transition-primary;
        width: 100%;
        z-index: 1;

        &.opened {
            box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, .4);
            // height: calc(100% - #{$main-header-height});
            height: 100%;
            top:0;
            overflow-y: hidden;
            position: fixed;
            transform: translateX( calc(-100% + 30px) );
        }

        .sub-menu-flex {
            height: 100%;
            overflow-y: auto;
        }

        .sub-menu-title {
            border-left: 5px solid transparent;
            box-sizing: border-box;
            color: $font-color-primary;
            display: block;
            font-size: $font-size-primary;
            font-weight: 700;
            line-height: 1;
            padding: $spacing-m $spacing-xl;
            overflow: hidden;
            text-decoration: none;
            text-overflow: ellipsis;
            text-transform: none;
            transition: $transition-primary;
            width: 100%;
            white-space: nowrap;

            @include breakpoint(0, $phone-small) {
                padding-left: 25px !important;
            }

            &:hover,
            &.active {
                background: $bg-color-primary;
                border-left: 5px solid $brand-color;
                color: $font-color-primary;
                text-decoration: none;
            }
        }
    }

    @include breakpoint($main-nav-breakpoint) {
        background-color: $bg-color-secondary;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
        top: 100%;
        width: 100vw;

        visibility: hidden;
        animation: resetTranslateY .3s cubic-bezier(.2, 1, .3, 1) 0s forwards;
        transform: translateY(-10px);

        .sub-menu-list {
            visibility: hidden;
            animation: resetTranslateY .6s cubic-bezier(.2, 1, .3, 1) 1s forwards;
            transform: translateY(-10px);

            @for $i from 1 through 50 {
                &:nth-child(#{$i}) {
                    animation-delay: (.05s * ($i - 1)) + .1s;
                }
            }
        }
    }
}

.sub-menu-wrap {

    .sub-menu-title {
        visibility: hidden;

        @include breakpoint($main-nav-breakpoint) {
            visibility: inherit;
        }
    }

    &.opened {
        .sub-menu-list {
            @for $i from 1 through 50 {
                &:nth-child(#{$i}) {
                    .sub-menu-title {
                        visibility: hidden;
                        animation: resetTranslateY .6s cubic-bezier(.2, 1, .3, 1) 1s forwards;
                        transform: translateY(-10px);
                        animation-delay: (.05s * ($i - 1)) + .1s;
                    }
                }
            }
        }
    }
}

.sub-menu-list {
    .nav-list {
        li {
            visibility: hidden;

            @include breakpoint($main-nav-breakpoint) {
                visibility: inherit;
            }
        }

        &.opened {
            li {
                @extend .animate-cascade-down;
            }
        }
    }
}

.sub-menu-title {
    cursor: pointer !important;

    svg {

        @include breakpoint($main-nav-breakpoint) {
            display: none;
        }
    }

    @include breakpoint($main-nav-breakpoint) {
        cursor: default !important;
    }
}

.sub-menu-list {

    @include breakpoint(0, ($main-nav-breakpoint - 1) ) {
        .nav-list {
            background-color: $bg-color-secondary;
            box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, .4);
            height: 100%;
            left: 100%;
            padding-top: $main-header-height;
            position: absolute;
            transform: translateX(0);
            transition: $transition-primary;
            width: 100%;
            top: 0;
            z-index: 1;

            &.opened {
                display: block;
                overflow-y: auto;
                transform: translateX( calc(-100% + 30px) );

                .nav-link {
                    width: 250px;
                }
            }
        }
    }
}

.sub-menu-flex {

    @include breakpoint($main-nav-breakpoint) {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        height: 323px;
        margin: $spacing-m auto $spacing-xs;
        max-width: $desktop-medium;
        overflow: hidden;
        width: 100%;

        .sub-menu-list {
            border-right: 1px dotted lightgrey;
            padding-bottom: $spacing-m;
            width: 22%;

            .nav-list {
                display: inline-block;
                list-style-type: none;
                padding-left: 0;
            }

            .nav-link {
                padding: ($spacing-s / 2) $spacing-s;
            }
        }
    }

    @include breakpoint($main-nav-breakpoint-2) {
        width: 80%;
    }
}

.sub-menu-title {
    @extend .footer-title;
    padding-left: $spacing-s !important;

    @include breakpoint(0, ($main-nav-breakpoint - 1) ) {
        padding-left: 25px !important;
    }
}

.nav-overlay-sub {
    background: rgba(0, 0, 0, .4);
    z-index: 1;

    &.active {
        .back-wrap {
            transform: translateX( calc(-100% + 30px) );
        }
    }
}

.nav-overlay-sub-sub {
    background: rgba(0, 0, 0, .2);
    z-index: 0;
}


.back-wrap-sub {
    background-color: white;
    height: $main-header-height;
    left: 100%;
    position: fixed;
    top: 0;
    transform: translateX(0);
    transition: $transition-primary;
    width: 100%;
    z-index: 10000;

    &.push {
        transform: translateX( calc(-100% + 30px) );
    }

    &.push-again {
        transform: translateX( calc(-100% + 60px) );
    }



    .back-sub {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        cursor: pointer;
        height: 100%;
        margin: 0;
        padding: 0 $spacing-xl;

        .back-arrow {
            font-size: 24px;
            position: relative;
            right: 8px;
        }
    }
}

.fixed-point {
    @include breakpoint(0, ($main-nav-breakpoint - 1) ) {
        height: 100%;
        overflow-y: auto;
        top: 0;
        width: 100%;
    }
}
