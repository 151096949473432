// Main Header
@import 'css/grid.scss';
@import 'css/mixins.scss';

.back-btn {
    border: none;
    height: 56px;
    padding: 0;
    display: flex;
    width: 87px;
    align-items: center;
    justify-content: center;
    background: transparent;
    font-size: 18px;
    font-weight: 700;
    color: #47c132;
    padding-right: 8px;
}

.back-btn-icon {
    color: $brand-color;
}
