@import 'css/config.scss';

// Mixins
@mixin breakpoint($param1, $param2: false) {

    $declaration: 'only screen and (min-width: #{$param1})';

    @if ($param2 != false) {
        $declaration: 'only screen and (min-width: #{$param1}) and (max-width: #{$param2})';
    }

    @media #{$declaration} {
        @content;
    }
}

@mixin bg-gradient($color) {
    background: $color;
    background: linear-gradient(to bottom, lighten($color, 5%) 0%, darken($color, 5%) 100%); //sass-lint:disable-line no-duplicate-properties
}

@mixin input-description {
    color: darken($border-color-primary, 15%);
    clear: both;
    display: block;
    font-size: $input-font-size * .85;
    line-height: 1.25;
    padding: ($input-font-size / 2) $input-font-size;

    &.error { // Silverstripe form variation
        color: $input-error-color;
    }
}

// Fonts
@mixin h1 {
    font-family: $font-family-primary;
    font-weight: 700;
    // 30px/36px
    font-size: $font-size-h1;
    line-height: $font-size-h1*1.2;
    letter-spacing: 0;
    color: $brand-color;
    margin: 30px 0 15px 0;

    a {
        color: $brand-color;
    }
}

@mixin h2 {
    color: $font-color-primary;
    font-family: $font-family-primary;
    font-weight: 700;
    // 30px/36px
    font-size: $font-size-h2;
    line-height: $font-size-h2*1.2;
    letter-spacing: 0;
    margin: 30px 0 15px 0;
}

@mixin h3 {
    color: $brand-color;
    font-weight: 700;
    // 20px/24px
    font-size: $font-size-h3;
    line-height: $font-size-h3*1.2;
    letter-spacing: 0;
    margin: 10px 0 15px 0;
}

@mixin h4 {
    color: $font-color-primary;
    font-weight: 700;
    // 20px/30px
    font-size: $font-size-h4;
    line-height: $font-size-h4*1.5;
    letter-spacing: 0;
}

@mixin h5 {
}

@mixin h6 {
    color: $font-color-primary;
    font-size: $font-size-h6;
    line-height: $font-size-h6;
    font-weight: 500;
    letter-spacing: 0;
    margin: 0;
}
