@import 'css/config.scss';

.react-calendar {
    // border-color: $brand-color;
    border-color: $border-color-primary;
    font-family: $font-family-primary;
    padding: 10px;

    abbr[data-original-title],
    abbr[title] {
        color: #aaa;
        font-weight: 300;
        text-decoration: none;
    }
}

.react-calendar__month-view {
    // padding: 0 10px;
}

.react-calendar__month-view__weekdays {
    border-bottom: 1px solid #eee;
}

.react-calendar__navigation__label {
    text-transform: uppercase;
    font-size: 14px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
    font-size: 1.5rem;
}

.react-calendar__tile {
    &:disabled {
        background: #fff;
        color: #ccc;
    }
}

.react-calendar__tile--active {
    background: inherit;
    color: #000;
    border: 2px solid $brand-color !important;
    border-radius: 50%;
}
