@import 'css/config.scss';
@import 'css/mixins.scss';

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// General
html {
    font-size: $font-size-base;
    font-family: $font-family-primary;
    height: 100%;
    line-height: 1;
    scroll-behavior: smooth;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: $font-color-primary;
    font-family: $font-family-primary;
    font-size: $font-size-base;
    font-weight: 400;
    height: 100%;
    margin: 0;
}

#root {
    height: 100%;
}

main {
    background: $main-background-color;
    flex-basis: auto;
    flex: 1 1 auto;
    z-index: 0;
}

hr {
    border-top-color: #D4D4D4;
}

input[type=text],
input[type=number],
select:focus,
textarea {
    // We need this to avoid zoom effect on mobile when focusing an input field:
    // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
    font-size: 16px;
}

input::placeholder,
textarea::placeholder {
    font-size: $font-size-base;
}

// Safari fix for inner shadow
// https://stackoverflow.com/questions/3062968/remove-textarea-inner-shadow-on-mobile-safari-iphone
textarea,
input:not([type=checkbox]):not([type=radio]) {
    -webkit-appearance: none;
}

a {
    color: $brand-color-2;

    &:hover {
        color: darken($brand-color-2, 10%);
    }
}


/*******************************************
* InterCity Website _globals.scss
*******************************************/
::selection {
    background: $brand-color;
    color: $font-color-secondary;
}

h1,
.heading-1 {
    color: $brand-color;
    font-size: $font-size-h1 * .8;
    font-weight: 600;
    line-height: 1.1;
    margin: ($font-size-h1 / 2) 0 ($font-size-h1 / 2.5);
    transition: $transition-primary;

    @include breakpoint($phone-medium) {
        font-size: $font-size-h1;
    }

    &.alt {
        color: $font-color-secondary;
    }

    &.--brand-color-2 {
        color: $brand-color-2;
    }

    &.--brand-color-4 {
        color: $brand-color-4;
    }
}

%heading-2 {
    color: $font-color-primary;
    font-size: $font-size-h2;
    font-weight: 300;
    line-height: 1.1;
    margin: ($font-size-h2 / 2) 0 ($font-size-h2 / 2.5);
    transition: $transition-primary;
}

h2,
.heading-2 {
    @extend %heading-2;

    &.alt {
        color: $font-color-secondary;
    }

    &.alt-2 {
        color: $brand-color-2;
    }
}

%heading-3 {
    color: $font-color-primary;
    font-size: $font-size-h3;
    font-weight: 400;
    line-height: 1.1;
    margin: ($font-size-h3 / 2) 0 ($font-size-h3 / 2.5);
    transition: $transition-primary;
}

h3,
.heading-3 {
    @extend %heading-3;

    &.alt {
        color: $font-color-secondary;
    }

    &.alt-2 {
        color: $brand-color-2;
    }
}

%heading-4 {
    color: $font-color-quaternary;
    font-size: $font-size-h4;
    font-weight: 600;
    line-height: 1.1;
    margin: ($font-size-h4 / 2) 0 ($font-size-h4 / 2.5);
    text-transform: uppercase;
    transition: $transition-primary;
}

h4,
.heading-4 {
    @extend %heading-4;

    &.alt {
        color: $font-color-secondary;
    }
}

%heading-5 {
    color: $font-color-primary;
    font-size: $font-size-h5;
    font-weight: 600;
    line-height: 1.1;
    margin: ($font-size-h5 / 2) 0 ($font-size-h5 / 2.5);
}

h5,
.heading-5 {
    @extend %heading-5;

    &.alt {
        color: $font-color-secondary;
    }
}

%heading-6 {
    color: $font-color-primary;
    font-size: $font-size-h6;
    font-weight: 300;
    line-height: 1.1;
    margin: ($font-size-h6 / 2) 0 ($font-size-h6 / 2.5);
}

h6,
.heading-6 {
    @extend %heading-6;

    &.alt {
        color: $font-color-secondary;
    }
}

.subtitle {
    margin: 0 $spacing-m $spacing-xl;
}

.subtitle-link {
    color: $font-color-primary;

    &:hover {
        color: $font-color-primary;
    }

    .icon {
        margin: 0;
    }
}

.no-border {
    border: 0;
}

.no-text-decoration {
    &:hover {
        text-decoration: none;
    }
}

a {
    color: $link-color;
    text-decoration: none;
    transition: $transition-fast;

    &:hover {
        color: $link-hover-color;
        text-decoration: underline;
    }

    &.font-color-2 {
        color: $brand-color-2;
    }

    &.font-color-4 {
        color: $brand-color-4;
    }

    .icon {
        vertical-align: middle;
    }
}

strong,
.strong {
    font-weight: 700;
}

em {
    font-style: italic;
}

img {
    height: auto;
    max-width: 100%;
}

textarea {
    resize: vertical;
}

input {
    font-family: $font-family-primary;
}

p,
.text {
    color: $font-color-primary;
    font-family: $font-family-primary;
    font-size: $font-size-primary;
    font-weight: 400;
    line-height: 1.2;
    margin: $spacing-m 0;
}

.text-small {
    @extend .text;
    font-size: $font-size-small;
    line-height: 1;
    margin: 0 0 $spacing-s;
}

.text-xsmall {
    @extend .text-small;
    font-size: $font-size-xsmall;
}

.text-large {
    @extend .text;
    font-size: $font-size-large;
}

.text-ellipsed {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-bold {
    font-weight: 700;
}

.text-highlight {
    font-weight: 600;
}

.text-light {
    font-weight: 300;
}

.text-thin {
    font-weight: 100;
}

.text-italic {
    font-style: italic;
    font-weight: 400;
}

// Base Layout
.main-content {
    padding: $main-header-height 0 0;

    &.level-0 {
        padding: 0;
    }
}

.homepage .main-content {
    padding: 0;
    position: relative;
}

.wrapper {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 5%;
    width: 100%;

    @include breakpoint($max-width) {
        width: $max-width;
    }
}

.wrapper-slim {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 5%;
    width: 100%;

    @include breakpoint($desktop-small) {
        width: $desktop-small;
    }

    @include breakpoint($desktop-small + 100px) {
        padding: 0;
    }
}

.wrapper-spaced {
    box-sizing: border-box;
    margin: $spacing-xxl auto;
    padding: 0 5%;
    width: 100%;

    @include breakpoint($max-width) {
        width: $max-width;
    }
}

// Placeholders
%hidden {
    opacity: 0;
    visibility: hidden;
}

%visible {
    opacity: 1;
    visibility: visible;
}

// Common rules
.hidden,
.is-hidden {
    @extend %hidden;
}

.visible,
.is-visible {
    @extend %visible;
}

.centered {
    text-align: center;
}

.rounded {
    border-radius: 10px;
}

.vertical-centered {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    &.alt {
        position: absolute;
    }
}

.position-relative {
    position: relative;
}

// TODO: Move this class inside of _list.scss
.non-styled-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.divider {
    @extend .wrapper;
    background: $bg-color-quaternary;
    display: block;
    height: 1px;
    position: relative;
    width: 90%;

    @include breakpoint($max-width) {
        width: calc(#{$max-width} - 10%);
    }

    &:before,
    &:after {
        background: $bg-color-secondary;
        content: '';
        height: 1px;
        position: absolute;
        right: -2000px;
        top: 0;
        width: 2000px;
    }

    &:after {
        left: -2000px;
    }

    &.brand-color {
        background: $brand-color;
    }

    &.dark {
        background: $border-color-tertiary;
    }

    &.bg-quinary {
        &:before,
        &:after {
            background: $bg-color-quinary;
        }
    }

    &.full-width {
        width: 100%;
    }

    &.has-spacing {
        margin: $spacing-xl auto $spacing-xxl;
    }
}

// Page container
.section-container {
    background: $bg-color-secondary no-repeat center center/cover; // sass-lint:disable-line space-around-operator
    margin: 0;
    padding: $spacing-xxl 0 ($spacing-xxl + $spacing-s);
    position: relative;
    width: 100%;

    @include breakpoint($tablet) {
        padding: ($spacing-xxl * 2) 0 ($spacing-xxl * 2 + $spacing-xs);
    }

    &.no-spacing {
        padding: 0;
    }

    &.centered-content {
        text-align: center;
    }

    &.dark {
        background: $bg-color-quinary no-repeat center center/cover; // sass-lint:disable-line space-around-operator
        box-shadow: inset 0 10px 20px -10px rgba(0, 0, 0, .3), inset 0 -10px 20px -10px rgba(0, 0, 0, .3);
    }

    &.dark-overlay,
    &.light-overlay {
        position: relative;
        z-index: $z-bottom;

        &:after {
            background: rgba($black, .35);
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: $z-below-bottom;
        }
    }

    &.light-overlay:after {
        background: rgba($white, .35);
    }

    &.no-bg {
        background: transparent;
    }
}

// Z-levels
.z-depth-0 {
    box-shadow: none;
}

.z-depth-1 {
    box-shadow: 0 1px 8px rgba(0, 0, 0, .18);
}

.z-depth-2 {
    box-shadow: 0 3px 12px rgba(0, 0, 0, .21);
}

.z-depth-3 {
    box-shadow: 0 10px 18px rgba(0, 0, 0, .23);
}

.z-depth-4 {
    box-shadow: 0 14px 26px rgba(0, 0, 0, .26);
}

.z-depth-5 {
    box-shadow: 0 19px 35px rgba(0, 0, 0, .28);
}

.input-action {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $input-font-size;
    font-size: $font-size-base * 2;

    &.disabled {
        color: $input-disabled-color;
    }
}

.simple-box {
    background-color: #fff;
    border: 1px solid $border-color-secondary;
    padding: $spacing-m;

    @include breakpoint($tablet) {
        padding: $spacing-xl;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.inline-block {
    display: inline-block;
}

.system-messages {
    border: 2px solid $border-color-secondary;
    border-radius: 2px;
    background: $bg-color-quinary;
    padding: $spacing-m;
    margin-bottom: $spacing-m;
    /*
    Yeah, uppercase. The reason is that the existing code is using the resnet response
    as is (uppercase) as the class. If we want to get this in lowercases, we need to change
    every part of the code that is setting this value and lowercase it. For now, to make sure
    that we don't break anything, I will leave it as is.
    */
    &.Error {
        border-color: $error-color;
    }
}

.system-message {
    /*
    Yeah, uppercase. Read above (.system-messages.Error)
    */
    &.Error {
        color: $error-color;
    }
}

/*
Some modals have a big icon on the top of their content, usually
a warning or error icon, in that case we can use this class.
*/
.modal-icon {
    color: $font-color-quaternary;
    font-size: $font-size-xxxlarge*2.5;
}
