//! NOTE This file is a copy of the one we are using on intercity website

// sass-lint:disable-all
// Fonts
$font-family-primary        : "Nunito", sans-serif, Helvetica, Arial !default;
$font-size-base             : 16px !default;
$font-size-primary          : 1rem !default;
$font-size-xxsmall          : .625rem !default;
$font-size-xsmall           : .75rem !default;
$font-size-small            : .875rem !default;
$font-size-large            : 1.25rem !default;
$font-size-xlarge           : 1.5rem !default;
$font-size-xxlarge          : 2rem !default;
$font-size-xxxlarge         : 2.75rem !default;

$font-size-h1               : $font-size-xxxlarge !default;
$font-size-h2               : $font-size-xxlarge !default;
$font-size-h3               : $font-size-xlarge !default;
$font-size-h4               : $font-size-primary !default;
$font-size-h5               : $font-size-large !default;
$font-size-h6               : $font-size-small !default;

// General Colors
// @NOTE: Don't use this variables directly when styling
$black                      : #000 !default;
$white                      : #fff !default;
$gray-xx-light              : #f7f7f7 !default;
$gray-x-light               : #eee !default;
$gray-light                 : #ddd !default;
$gray                       : #ccc !default;
$gray-medium                : #aaa !default;
$gray-dark                  : #777 !default;
$gray-xx-dark               : #222 !default;
$info-color                 : #4a90e2 !default;
$success-color              : #00d235 !default;
$warning-color              : #e65100 !default;
$error-color                : #e91e63 !default;

// Color Pallete
// @NOTE: Use this variables when styling
$brand-color                : #47c132 !default;
$brand-color-1              : $brand-color !default;
$brand-color-2              : #009aff !default;
$brand-color-3              : #f5d027 !default;
$brand-color-4              : #ff7900 !default;
$brand-color-5              : #c1dd0f !default;
$brand-color-6              : #d1047b !default;
$brand-color-7              : #d4ab44 !default;

// For sass loop usage
$brand-colors:  $brand-color-1,
                $brand-color-2,
                $brand-color-3,
                $brand-color-4,
                $brand-color-5,
                $brand-color-6,
                $brand-color-7;

// More colors
$highlighted-item            : $brand-color-2;
$highlighted-item-font-color : #fff;

$flexi-pass-color: $brand-color-2;
$travel-pass-color: $brand-color-4;

// Color vars
// @NOTE: Use this variables when styling
$font-color-primary         : $gray-xx-dark !default;
$font-color-secondary       : $white !default;
$font-color-tertiary        : $gray !default;
$font-color-quaternary      : $gray-dark !default;
$font-color-quinary         : $gray-medium !default;
$link-color                 : $brand-color !default;
$link-hover-color           : darken($link-color, 10%) !default;
$bg-color-primary           : $gray-xx-light !default;
$bg-color-secondary         : $white !default;
$bg-color-tertiary          : $gray-xx-dark !default;
$bg-color-quaternary        : $gray-light !default;
$bg-color-quinary           : $gray-x-light !default;
$border-color-primary       : $gray !default;
$border-color-secondary     : $gray-light !default;
$border-color-tertiary      : $gray-dark !default;
$border-color-quaternary    : $white !default;

// Transitions
$transition-primary         : all .3s ease !default;
$transition-secondary       : all .6s cubic-bezier(.2, 1, .3, 1) !default;
$transition-tertiary        : all 1s cubic-bezier(.7, 0, .3, .7) !default;
$transition-quaternary      : all .8s cubic-bezier(.2, 1, .3, 1) !default;
$transition-fast            : all .2s ease !default;
$transition-slow            : all 1s ease !default;

// Breakpoints
$phone-small                : 320px !default;
$phone-small-plus           : 360px !default;
$phone-medium               : 480px !default;
$phone-large                : 640px !default;
$tablet                     : 768px !default;
$desktop-small              : 1024px !default;
$desktop-medium             : 1280px !default;
$desktop-large              : 1440px !default;

// General dimensions and spacing
$spacing-xxs                : 5px !default;
$spacing-xs                 : 10px !default;
$spacing-s                  : 15px !default;
$spacing-m                  : 20px !default;
$spacing-l                  : 24px !default;
$spacing-xl                 : 30px !default;
$spacing-xxl                : 45px !default;
$max-width                  : $desktop-medium !default;
$main-header-height         : 56px !default;
$main-nav-width             : 280px !default;
$main-nav-breakpoint        : $desktop-small !default;
$main-nav-breakpoint-2      : $desktop-medium !default;
$main-nav-logo-width        : 120px !default;
$cart-header-height         : 70px !default;
$fare-item-height           : 130px !default;
$counter-size               : 28px !default;

// Grid
$num-cols                   : 12 !default;
$gutter-width               : 1.5rem !default;
$gutter                     : $gutter-width;
$element-top-margin         : $gutter-width/3 !default;
$element-bottom-margin      : ($gutter-width*2)/3 !default;
$grid-breakpoint-pm         : $phone-medium;
$grid-breakpoint-pl         : $phone-large;
$grid-breakpoint-m          : $tablet;
$grid-breakpoint-l          : $desktop-small;

// Forms
$label-color                : lighten($font-color-primary, 50%) !default;
$input-focus-color          : $brand-color !default;
$input-disabled-color       : lighten($border-color-primary, 10%);
$input-error-color          : $error-color !default;
$input-hint-color           : $label-color !default;
$input-border               : 1px solid $border-color-primary !default;
$input-radius               : 5px !default;
$input-height               : 3.5rem !default;
$input-font-size            : $font-size-primary !default;
$radio-checkbox-size        : 18px !default;

// Z-index
$z-below-bottom             : -1 !default;
$z-bottom                   : 0 !default;
$z-above-bottom             : 1 !default;
$z-middle-bottom            : 5 !default;
$z-middle                   : 10 !default;
$z-middle-top               : 15 !default;
$z-top                      : 1000 !default;
$z-above-top                : 1005 !default;

$main-background-color      : #fff !default;
