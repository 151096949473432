@import 'css/config.scss';

// --- SVG Icons ---
.icon {
    fill: currentColor;
    height: 1em;
    overflow: hidden;
    vertical-align: -.15em;
    width: 1em;

    // Icon variation colors:
    // By default icon color is inherited by the font-color from the parent element
    // Colors goes from 1 to 8, matching $brand-colors defined on _config.scss file.
    // Searchable: brand-color-1, brand-color-2, brand-color-3, brand-color-4, brand-color-5, brand-color-6, brand-color-7, brand-color-8
    @for $i from 1 to length($brand-colors) + 1 {
        &.color-#{$i} {
            color: nth($brand-colors, $i);
        }
    }

    // @TODO: add any specific SVG icon variation here. Example:
    // &.variation {
    //     color: $bg-color-primary;
    //     font-size: $font-size-large;
    // }
}

// --- CSS Icons ---

// Menu icon
.menu-icon {
    background: $brand-color;
    display: block;
    height: 2px;
    left: $spacing-m;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    transition: $transition-primary;
    width: $main-header-height / 2;

    &:before,
    &:after {
        background: $brand-color;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        transition: $transition-primary;
        width: 100%;
    }

    &:before {
        transform: translateY(-300%);
    }

    &:after {
        transform: translateY(300%);
    }
}

// Close menu icon
.close-menu-icon {
    @extend .menu-icon;

    background: transparent;
    height: 2px;

    &:before {
        transform: translateY(0) rotate(45deg);
    }

    &:after {
        transform: translateY(0) rotate(-45deg);
    }
}

// Icon arrow down
.arrow-down-icon {
    background: transparent;
    display: block;
    height: 3px;
    position: absolute;
    right: $spacing-m;
    top: 50%;
    transform: translateY(-50%);
    transition: $transition-primary;
    width: 14px;

    &:before,
    &:after {
        background: $brand-color;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        transition: $transition-primary;
        width: 100%;
    }

    &:before {
        transform: translateX(-8px) rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

// Icon arrow up
.arrow-up-icon {
    @extend .arrow-down-icon;

    &:before {
        transform: translateX(-8px) rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }
}
