
@import 'css/icons.scss';
@import 'css/mixins.scss';

// Collapse
.collapse-toggle {
    color: $font-color-primary;
    cursor: pointer;
    margin: 0;
    padding: $spacing-xs ($spacing-xl * 1.3) $spacing-xs $spacing-s;
    position: relative;
    text-align: left;
    transition: $transition-primary;

    @include breakpoint($tablet) {
        padding: $spacing-xs ($spacing-xl * 2) $spacing-xs $spacing-s;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    &:hover,
    &:hover h4 {
        color: $brand-color;
    }

    &:hover .icon {
        color: $font-color-primary;
    }

    h4 {
        color: $font-color-primary;
        margin: 0;
        line-height: 1.5rem;

        @include breakpoint($tablet) {
            line-height: 1.1;
        }
    }

    .icon {
        display: none;

        & ~ p {
            padding: 0;
            clear: both;

            @include breakpoint($tablet) {
                clear: none;
            }
        }

        @include breakpoint($tablet) {
            $icon-size: 3rem;
            $icon-padding: .5rem;

            color: $font-color-primary;
            display: block;
            float: left;
            font-size: $icon-size;
            padding: 0 #{$icon-size / 3} 0 #{$gutter-width / 2};

            & ~ h4 {
                padding-left: #{$icon-size + ($icon-padding * 2)};
            }
        }
    }
}

.collapse-icon {
    @extend .arrow-down-icon;
    right: $spacing-xs;

    @include breakpoint($tablet) {
        right: $spacing-m;
    }
}

.collapsible {
    border-top: 1px solid $border-color-secondary;
    display: none;
}

.collapsible-container {
    padding: $spacing-s;
}

.collapse-group {
    background: #fff;
    border: 1px solid $border-color-secondary;
    box-sizing: border-box;
    position: relative;
    transition: $transition-primary;

    @include breakpoint($tablet) {

        &.mobile-only {
            border: 0;

            .collapse-toggle,
            .collapse-icon {
                display: none;
            }

            .collapsible {
                border: 0;
                display: block;
            }
        }
    }

    &.--brand-color-2 {
        &.opened {
            .collapse-toggle {
                color: $brand-color-2;
            }
        }

        .collapse-toggle {
            &:hover,
            &:hover h4 {
                color: $brand-color-2;
            }
        }

        .collapse-icon {
            &:before,
            &:after {
                background: $brand-color-2;
            }
        }
    }

    &.--brand-color-4 {
        &.opened {
            .collapse-toggle {
                color: $brand-color-4;
            }
        }

        .collapse-toggle {
            &:hover,
            &:hover h4 {
                color: $brand-color-4;
            }
        }

        .collapse-icon {
            &:before,
            &:after {
                background: $brand-color-4;
            }
        }
    }

    p {
        font-size: $font-size-small;
        font-weight: 400;
        margin: $spacing-xxs 0;
        padding: $spacing-xxs $spacing-s;
        text-align: left;
    }

    .collapsible-container {
        padding: $spacing-s;

        p {
            padding: 0;
        }
    }

    // Variation white background
    &.bg-color-secondary {
        background: $bg-color-secondary;
        border-color: $border-color-primary;
    }

    + .collapse-group {
        margin-top: -1px;
    }
}

// Opened
.collapse-group.opened {
    box-shadow: 0 1px 8px rgba(0, 0, 0, .18);
    margin: $spacing-xs 0;

    .collapse-toggle {
        color: $brand-color;
    }

    .collapse-icon {
        @extend .arrow-up-icon;
        right: $spacing-xs;

        @include breakpoint($tablet) {
            right: $spacing-m;
        }
    }

    &.mobile-only {
        @include breakpoint($tablet) {
            box-shadow: none;

            .collapse-icon {
                display: none;
            }
        }
    }
}
