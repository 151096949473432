@import 'config';
@import 'mixins';
@import 'animations';
@import 'icons';
@import 'fonts';
@import 'global';
@import 'grid';
@import 'collapse';
@import 'navigation-main';
@import 'nav-sub-menu';
@import 'footer';

// Custom overwrites
.main-header-logo {
    font-size: 2rem;
    height: 56px;
}

.main-nav {
    .back-wrap-sub .back-sub .back-arrow {
        font-size: 14px;
    }
}

.main-logo-link {
    display: block;
    height: 100%;
}

.help-link .forward-arrow {
    font-size: 1rem;
    margin-left: 5px;

    @include breakpoint($desktop-small) {
        font-size: .6rem;
    }
}

.sub-menu-title {
    > .icon {
        font-size: 1rem;
        margin-left: 5px;

        @include breakpoint($desktop-small) {

        }
    }
}
