.App {
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  height: 100%;
}

.Main {
  flex: 1 1 auto;
  z-index: 1;
}
