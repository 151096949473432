@import 'css/config.scss';
@import 'css/mixins.scss';

h1,
.h1 {
    @include h1;

    // Used as little description below main title, ie:
    // "from Queenstown"
    + .subtitle {
        margin-top: -#{$gutter/2};
    }
    @include breakpoint($tablet) {
        font-size: 3.2rem;
    }
}

.subtitle {
    font-size: 1rem;
    font-weight: 700;
}


// NOTE Right now this is exactly the same as h1
h2,
.h2 {
    @include h2;
}

h3,
.h3 {
    @include h3;
}

h4,
.h4 {
    @include h4;
}

h5,
.h5 {
    @include h5;
}

// Used as little description below main title, ie:
// "from Queenstown"
h6,
.h6 {
    @include h6;
}

.text-color-primary {
    color: $font-color-primary;
}

.text-color-white {
    color: #fff;
}
