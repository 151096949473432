// Keyframes
@keyframes resetScale {
    0% {} //sass-lint:disable-line no-empty-rulesets

    100% {
        opacity: 1;
        transform: scale(1);
        visibility: visible;
    }
}

@keyframes resetTranslateY {
    0% {} //sass-lint:disable-line no-empty-rulesets

    100% {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }
}

@keyframes slideshowZoomIn {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.3);
    }
}

// Animation - Cascade up
.animate-cascade-up {
    @extend %hidden;
    animation: resetTranslateY .3s cubic-bezier(.2, 1, .3, 1) 1s forwards;
    transform: translateY(100px);

    @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
            animation-delay: (.04s * ($i - 1)) + .1s;
        }
    }
}

// Animation - Cascade up classes
.animate-cascade-up-0 {
    @extend %hidden;
    animation: resetTranslateY .3s cubic-bezier(.2, 1, .3, 1) 1s forwards;
    transform: translateY(100px);
}

@for $i from 1 through 30 {
    .animate-cascade-up-#{$i} {
        @extend .animate-cascade-up-0;
        animation-delay: .04s * ($i - 1);
    }
}

// Animation - Cascade down
.animate-cascade-down {
    @extend %hidden;
    animation: resetTranslateY .6s cubic-bezier(.2, 1, .3, 1) 1s forwards;
    transform: translateY(-10px);

    @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
            animation-delay: (.05s * ($i - 1)) + .1s;
        }
    }
}

// Animation - Cascade down classes
.animate-cascade-down-0 {
    @extend %hidden;
    animation: resetTranslateY .6s cubic-bezier(.2, 1, .3, 1) 1s forwards;
    transform: translateY(-10px);
}

@for $i from 1 through 30 {
    .animate-cascade-down-#{$i} {
        @extend .animate-cascade-down-0;
        animation-delay: (.05s * ($i - 1) + .1s);
    }
}

// Animation - Appear
.animate-appear {
    @extend %hidden;
    animation: resetScale .6s cubic-bezier(.2, 1, .3, 1) 1s forwards;
    //transform: translateY(-10px);

    @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
            animation-delay: (.04s * ($i - 1) + .1s);
        }
    }
}

// Animation - Appear classes
.animate-appear-0 {
    @extend %hidden;
    animation: resetScale .6s cubic-bezier(.2, 1, .3, 1) 1s forwards;
    transform: scale(0);
}

@for $i from 1 through 30 {
    .animate-appear-#{$i} {
        @extend .animate-cascade-down-0;
        animation-delay: (.04s * ($i - 1) + .1s);
    }
}

// Animation helpers
.delayed-animation {
    animation-delay: .5s;
}

// Checkmark Animation
$anim-checkmark-curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.anim-checkmark {
    animation: fill-checkmark .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    border-radius: 50%;
    box-shadow: inset 0 0 0 $brand-color;
    display: inline-block;
    height: 56px;
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 2;
    width: 56px;
  }

.anim-checkmark-circle {
  animation: stroke .6s $anim-checkmark-curve forwards;
  fill: none;
  stroke: $brand-color;
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.anim-checkmark-check {
  animation: stroke .3s $anim-checkmark-curve .8s forwards;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  transform-origin: 50% 50%;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill-checkmark {
  100% {
    box-shadow: inset 0 0 0 90px $brand-color;
  }
}
