@import 'css/config.scss';
@import 'css/mixins.scss';

.nav-tabs {
    border-bottom: 2px solid #dee2e6;
    display: flex;
}

.nav-tabs .nav-item {
    flex: 1 1 auto;
    font-weight: 700;
    color: #000;
    border: none;
    text-align: center;

    &.active {
        color: $brand-color;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid $brand-color;
        margin-bottom: -2px;
    }
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
}

.btn {
    font-weight: 700;
    min-width: 180px;
    padding: 12px 18px;
    text-transform: uppercase;

    &.disabled,
    &:disabled,
    &.disabled:hover,
    &:disabled:hover {
        color: #444;
        border-color: $bg-color-quaternary !important;
        background: $bg-color-quaternary !important;
    }
}

.btn-primary {
    background-color: $brand-color-1;
    border-color: $brand-color-1;
    text-transform: none;

    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
        background-color: darken($brand-color-1, 4%) !important;
        border-color: darken($brand-color-1, 4%) !important;
    }

    &:focus
    {
        box-shadow: 0 0 0 0.2rem rgba(71,193,50,.5) !important;
    }
}

.btn-secondary {
    background-color: #fff;
    border-color: $brand-color;
    color: $brand-color;

    &:hover:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled) {
        background-color: #fff !important;
        border-color: $brand-color !important;
        color: $brand-color !important;
    }

    &:focus:active:not(:disabled):not(.disabled) {
        box-shadow: 0 0 0 0.2rem rgba(71, 193, 50, .5);
    }
}

.btn-warning {
    background-color: $brand-color-4;
    border-color: $brand-color-4;
    color: $font-color-secondary;text-transform: none;

    &:hover:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled) {
        color: $font-color-secondary !important;
        background-color: darken($brand-color-4, 4%) !important;
        border-color: darken($brand-color-4, 4%) !important;
    }

    &:focus:active:not(:disabled):not(.disabled) {
        box-shadow: 0 0 0 0.2rem rgba(255,121,0,.5) !important;
    }
}

.container-xs-small-gutter {
    @include breakpoint(0, 414px) {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.row-xs-small-gutter {
    @include breakpoint(0, 414px) {
        margin-right: -5px;
        margin-left: -5px;
    }
}

.col-xs-small-gutter {
    @include breakpoint(0, 414px) {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.table td,
.table th {
    padding-bottom: .75rem;
    padding-top: .75rem;
}

.table-responsive-sm {
    table {
        margin-bottom: 0;
    }
}
